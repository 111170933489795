import css from "@emotion/css"
import { Column, Columns, Container, Content, Section } from "bloomer"
import debug from "debug"
import { graphql, Link } from "gatsby"
import React from "react"
import ImageWrap from "../atoms/image-wrap.atom"
import ModelsCarouselBlock from "../blocks/models-carousel.block"
import { NewsTitle } from "../components/news-template.component"
import withPreviewRest from "../components/withPreviewRest"
import { THEMES } from "../config/theme"
import { LIGHT } from "../consts/colors.constants"
import Layout from "../layouts"

export const PostTemplate = props => {
  const { post } = props.data
  const { acf, title, content, categories, date } = post
  debug(`PostTemplate`)(props)
  const { page_theme } = `light`
  const theme = THEMES[page_theme] || THEMES.default
  return (
    <Layout
      className={`theme-${theme.name} template-post`}
      templateNamd={`template-post`}
      isHome={false}
      title={title || name}
      acf={{
        ...acf,
        // page_slider_images: [post.featured_media],
        // page_sloped: `right`,
        // page_sloped_amount: 30,
      }}
      themeName={page_theme}
    >
      {post && (
        <Section
          css={css`
            background-color: ${LIGHT};
            margin-top: 6rem;
          `}
        >
          <Container>
            <Columns>
              <Column>
                <Content hasTextAlign={`left`}>
                  <time>{date}</time>
                  <NewsTitle hasTextAlign={`left`}>{title}</NewsTitle>
                </Content>
              </Column>
            </Columns>
            <Columns>
              <Column isSize={12}>
                <div>
                  <ImageWrap
                    css={css`
                      max-height: 485px;
                      overflow: hidden;
                    `}
                    image={post.featured_media}
                    image_height={485}
                  />
                </div>
              </Column>
            </Columns>
            <Columns>
              <Column isSize={1}>
                <div>[SHAREBUTTONS PLACEHOLDER]</div>
              </Column>
              <Column isSize={8} isOffset={1}>
                <div
                  css={css`
                    margin-top: 3rem;
                    p {
                      margin-bottom: 1rem;l
                    }
                  `}
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </Column>
            </Columns>
            <Columns>
              <Column>
                <div style={{ marginTop: `4rem`, marginBottom: `4rem` }}>
                  {categories && categories.length ? (
                    <div>
                      <h4>Categories</h4>
                      <ul className="taglist">
                        {categories.map(category => (
                          <li key={`${category.slug}cat`}>
                            <Link to={`/categories/${category.slug}/`}>{category.name}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                </div>
              </Column>
            </Columns>
          </Container>
        </Section>
      )}
      <ModelsCarouselBlock carouselStyle={`category`} />
    </Layout>
  )
}

const Post = props => {
  /**
   * Determine if we're looking at a preview or live page.
   */
  const postData = props.preview ? props.data.post.revisions.nodes[0] : props.data.post
  return <PostTemplate {...{ ...props, data: { post: postData } }} />
}

export default withPreviewRest({ endpoint: `/posts` })(Post)

export const postQuery = graphql`
  fragment ImagePostFeatured on File {
    childImageSharp {
      fluid(maxHeight: 485) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  fragment WPPost on wordpress__POST {
    ...WPPostFields
    featured_media {
      source_url
      localFile {
        ...ImagePostFeatured
      }
    }
  }
  fragment WPPostFields on wordpress__POST {
    id
    title
    excerpt
    slug
    content
    path
    date(formatString: "MMMM DD, YYYY")
  }
  query GET_POST($id: String) {
    post: wordpressPost(id: { eq: $id }) {
      ...WPPost
    }
  }
`
