import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"

import { ModelsCarousel } from "../components/model-carousel.component"

const ModelsCarouselBlock = props => {
  const { carouselStyle } = props

  const query = graphql`
    query carouselQuery {
      models: allWordpressWpModel {
        edges {
          node {
            ...ModelCarouselHomeFields
          }
        }
      }
      models_category: allWordpressWpModel {
        edges {
          node {
            ...ModelCarouselCategoryFields
          }
        }
      }
      placeholder: file(relativePath: { eq: "gallery/gallery-placeholder.png" }) {
        ...ModelGalleryImage
      }
    }
  `

  const data = useStaticQuery(query)

  if (!data || (!data.models && !data.models_category)) {
    return null
  }

  const models =
    carouselStyle === `category`
      ? data.models_category.edges.map(({ node }) => node)
      : data.models.edges.map(({ node }) => node)
  return (
    <ModelsCarousel
      carouselStyle={carouselStyle || `home`}
      models={models}
      placeholderImage={data.placeholder}
    />
  )
}

export default ModelsCarouselBlock
